import axios from "axios";

import { showConfrimBox } from '../../../shared/ComfirmAlertBox/ConfirmAlertBox';
class HttpApi {
  // host = "http://dev.foodhub.api.bizminds.io";
  host = "https://api.pizzeriaplazafoodhub.gi";
  // host = "https://localhost:44332"
  token = "";

  setToken(token) {
    axios.defaults.headers.common["authorization"] = "Bearer " + token;
  }

  sendGetRequest = async (url) => {
    const res = await axios.get(`${this.host}${url}`);
    const success = res.status;

    if (success === 200) {
      return res.data;
    }
    else if (success === 400 && res.data.message) {
      showConfrimBox({
        title:'Validation Error',
        description:res.data.message,
        onOkClick:()=>{ }
      })
      throw new Error( res.data);
    } else {
      const { errMessage } = res.data;
      throw new Error(errMessage);
    }
  };

  sendPostRequest = async (url, data, props) => {
    let authToken = null;

    if (props && props.authToken) {
      authToken = props.authToken
    }

    if (authToken) {
      const res = await axios.post(`${this.host}${url}`, data, {
        headers: {
          'authorization': "Bearer " + authToken
        }
      });
      const success = res.status;
      if (success === 200) {
        return res.data;
      }
      else if (success === 400 && res.data.message) {
        showConfrimBox({
          title:'Validation Error',
          description:res.data.message,
          onOkClick:()=>{ }
        })
        throw new Error( res.data);
      }
      else {
        const { errMessage } = res.data;
        throw new Error(errMessage);
      }
    } else {
      const res = await axios.post(`${this.host}${url}`, data);
      const success = res.status;
      if (success === 200) {
        return res.data;
      } else {
        const { errMessage } = res.data;
        throw new Error(errMessage);
      }
    }
  };

  sendPutRequest = async (url, data, props) => {
    let authToken = null;
    if (props && props.authToken) {
      authToken = props.authToken
    }

    if (authToken) {
      const res = await axios.put(`${this.host}${url}`, data, {
        headers: {'authorization': "Bearer " + authToken}
      });
      const success = res.status;
      if (success === 200) {
        return res.data;
      }
      else if (success === 400 && res.data.message) {
        showConfrimBox({
          title:'Validation Error',
          description:res.data.message,
          onOkClick:()=>{ }
        })
        throw new Error( res.data);
      } else {
        const { errMessage } = res.data;
        throw new Error(errMessage);
      }
    } else {
      const res = await axios.put(`${this.host}${url}`, data);
      const success = res.status;
      if (success === 200) {
        return res.data;
      } else {
        const { errMessage } = res.data;
        throw new Error(errMessage);
      }
    }
  };

  sendDeleteRequest = async (url, props) => {
    let authToken = null;

    if (props && props.authToken) {
      authToken = props.authToken
    }
    let headers = {};
    if (authToken) {
      headers = {
        'authorization': "Bearer " + authToken
      }
      
    } 
    const res = await axios.delete(`${this.host}${url}`, {
      headers: headers
    });
    const success = res.status;

    if (success === 200) {
      return res.data;
    }
    else if (success === 400 && res.data.message) {
      showConfrimBox({
        title:'Validation Error',
        description:res.data.message,
        onOkClick:()=>{ }
      })
      throw new Error( res.data);
    } else {
      const { errMessage } = res.data;
      throw new Error(errMessage);
    }
  };
}

export default new HttpApi();